import React from 'react'
import { VStack } from '@chakra-ui/react'
import Header from './Header'
import Mission from './Mission'
import MainBanner from './MainBanner'
import AmountBanner from './AmountBanner'
import Funds from './Funds'
import Footer from './Footer'

function Landing() {
  return (
    <VStack>
      <Header />
      <MainBanner />
      <Mission />
      <AmountBanner />
      <Funds />
      <Footer />
    </VStack>
  )
}

export default Landing
