import React from 'react'
import { Box, Flex, Container } from '@chakra-ui/react'
import DonateButton from './DonateButton'

const MainBanner = () => (
  <Box
    w="100%"
    h="600px"
    bgImage="/landing_bg.webp"
    bgRepeat="no-repeat"
    bgPosition="center center"
    bgSize="cover"
  >
    <Box
      w="100%"
      h="600px"
      bgGradient="linear(to-b, rgba(255,255,0,.4), rgba(255,255,0,.8))"
    >
      <Container maxW="container.lg">
        <Flex direction="column" justify="center" align="flex-start" h="600px">
          <Box bg="white" borderRadius={20} px={3} py={1} fontWeight={800}>
            Let’s contribute to let the Ukraine live
          </Box>
          <Box textStyle="h1" my={3} maxW={600}>
            Fundraising for helpless and causes you care about
          </Box>
          <Box
            fontSize={[18, 24]}
            fontWeight={800}
            lineHeight={1.2}
            mb={9}
            maxW={600}
          >
            It is a long established fact that a reader will be page distracted
            by the readable content of a pain
          </Box>
          <DonateButton size="lg" />
        </Flex>
      </Container>
    </Box>
  </Box>
)

export default MainBanner
