import React from 'react'
import { Button } from '@chakra-ui/react'
import HeartIcon from '../FA/solid/heart'

const DonateButton = ({ size = 'md', shadow = 0 }) => (
  <Button size={size} boxShadow={shadow}>
    <HeartIcon mr="2" />
    Donate now
  </Button>
)

export default DonateButton
