import React from 'react'
import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react'
import Landing from './Landing/index'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme(withDefaultColorScheme({ colorScheme: 'blue' }), {
  config,
  styles: {
    global: {
      body: {
        fontFamily: 'Raleway, sans-serif',
      },
    },
  },
  textStyles: {
    h1: {
      fontSize: [5 * 8, 6 * 8],
      lineHeight: 1,
      fontWeight: 800,
    },
    h2: {
      fontSize: [3 * 8, 4 * 8],
      lineHeight: 1,
      fontWeight: 800,
    },
    h3: {
      fontSize: [2 * 8, 2.5 * 8],
      lineHeight: 1,
      fontWeight: 800,
    },
    blockLabel: {
      color: 'blue.500',
      fontWeight: 800,
    },
  },
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Landing />
    </ChakraProvider>
  )
}

export default App
