import React from 'react'
import { Box, Flex, Image, Container, VStack } from '@chakra-ui/react'

const goals = [
  'Raise money from different sources',
  'Giving relief in rural area all over the world',
  'Gather all the money and giving relief in need',
  'Go to the country that really needs help',
]

const Goal = ({ id, title }) => (
  <Box>
    <Flex align="center">
      <Box
        bg="yellow.200"
        borderRadius="50%"
        w={34}
        minW={34}
        h={34}
        textAlign="center"
        fontSize={18}
        fontWeight={600}
        lineHeight="32px"
      >
        0{id}
      </Box>
      <Box fontSize={20} fontWeight={600} lineHeight={0.9} ml={2}>
        {title}
      </Box>
    </Flex>
    <Box mt={2} mb={4}>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur,
      pariatur esse animi temporibus iusto at dolorum
    </Box>
  </Box>
)

const Mission = () => (
  <Container maxW="container.lg">
    <Flex my={[10, 16]} direction={['column', 'column', 'row']}>
      <Box w={['100%', '100%', '50%']}>
        <Box textStyle="blockLabel" mb={3}>
          Our mission
        </Box>
        <Box textStyle="h2" mb={6}>
          We exist for non-profits, social enterprises, community groups
        </Box>
        <VStack align="flex-start">
          {goals.map((goal, indx) => (
            <Goal id={indx + 1} title={goal} key={`goal_${indx}`} />
          ))}
        </VStack>
      </Box>
      <Box
        ml={10}
        position="relative"
        w="50%"
        display={['none', 'none', 'block']}
      >
        <Image
          src="/mission_2.jpg"
          position="absolute"
          top="0"
          right="0"
          boxSize={['300px', '300px', '400px']}
          objectFit="contain"
        />
        <Image
          src="/mission_1.jpg"
          position="absolute"
          bottom="0"
          left="0"
          boxSize={['300px', '300px', '400px']}
          objectFit="contain"
        />
      </Box>
      <Box display={['block', 'block', 'none']} mt={5}>
        <Image src="/mission_1.jpg" boxSize="100%" objectFit="contain" />
      </Box>
    </Flex>
  </Container>
)

export default Mission
