import React from 'react'
import { Box, Flex, Container, Progress } from '@chakra-ui/react'
import DonateButton from './DonateButton'

const AmountBanner = () => (
  <Box
    w="100%"
    h="450px"
    bgImage="/help_ukraine.webp"
    bgRepeat="no-repeat"
    bgPosition="center center"
    bgSize="cover"
  >
    <Box
      w="100%"
      h="450px"
      bgGradient="linear(to-b, rgba(0,0,0,.7), rgba(0,0,0,.9))"
    >
      <Container maxW="container.lg">
        <Flex direction="column" justify="center" align="center" h="450px">
          <Box color="white" fontSize={30} textAlign="center" fontWeight={800}>
            WE ARE VERY THANKFUL
          </Box>
          <Box textStyle="h2" color="white" textAlign="center" mt={10}>
            You’ve helped us raise an overwhelming
          </Box>
          <Box textStyle="h1" color="blue.400" mt={3}>
            € 83.512
          </Box>
          <Progress
            w={['300px', '450px']}
            hasStripe
            value={32}
            colorScheme="yellow"
            mt={3}
            mb={10}
            borderRadius={20}
          />
          <DonateButton size="lg" />
        </Flex>
      </Container>
    </Box>
  </Box>
)

export default AmountBanner
