import React from 'react'
import { Image, Flex, Container, Box, VStack } from '@chakra-ui/react'
import FacebookIcon from '../FA/brands/facebook-f'
import TelegramIcon from '../FA/brands/telegram-plane'
import InstagramIcon from '../FA/brands/instagram'
import MapPinIcon from '../FA/solid/map-marker-alt'
import PhoneIcon from '../FA/solid/phone-alt'

const SocialIcon = ({ children }) => (
  <Box
    sx={{
      borderWidth: '2px',
      borderColor: 'blue.500',
      color: 'blue.500',
    }}
    w="35px"
    h="35px"
    textAlign="center"
    borderRadius="50%"
    mr={2}
  >
    {children}
  </Box>
)

const Footer = () => (
  <Box bg="yellow.300" py={10} w="100%">
    <Container maxW="container.lg">
      <Flex
        direction={['column', 'column', 'row']}
        justify="space-between"
        align="flex-start"
        mt={2}
      >
        <VStack align="flex-start" maxW="380px">
          <Image src="/logo.png" htmlWidth={200} fit="contain" mb={1} />
          <Box>
            Let's make this world a peacuful place together! With your help we
            can do much more to help people stay safe.
          </Box>
        </VStack>
        <VStack align="flex-start" spacing={5} mt={[10, 10, 0]}>
          <Box textStyle="h3">Contact info</Box>
          <Box>
            <Flex align="center">
              <MapPinIcon mr={3} /> Ukraine, Kyiv, 43 Olesya Honchara st.
            </Flex>
            <Flex align="center" mt={2}>
              <PhoneIcon mr={3} /> +123-456-789
            </Flex>
          </Box>
          <Flex>
            <SocialIcon>
              <FacebookIcon fontSize="20px" mt="4px" />
            </SocialIcon>
            <SocialIcon>
              <TelegramIcon fontSize="20px" mt="4px" mr="2px" />
            </SocialIcon>
            <SocialIcon>
              <InstagramIcon fontSize="20px" mt="4px" />
            </SocialIcon>
          </Flex>
        </VStack>
      </Flex>
    </Container>
  </Box>
)

export default Footer
