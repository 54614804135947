import React from 'react'
import { Box, Flex, Container, Image } from '@chakra-ui/react'
import DonateButton from './DonateButton'

const Header = () => (
  <Container maxW="container.lg">
    <Flex justify="space-between" align="center" mt={2}>
      <Box w={['150px', '200px']}>
        <Image src="/logo.png" fit="contain" mt={2} mb={1} />
      </Box>
      <DonateButton />
    </Flex>
  </Container>
)

export default Header
