import React from 'react'
import { Box, Flex, Image, Container, Progress } from '@chakra-ui/react'
import DonateButton from './DonateButton'

const funds = [
  {
    id: 'medical',
    title: 'Medical help, medicine',
    description:
      'Help people who need medical help with medicine. Offer support to the ukrainian medical facilities.',
    money: { goal: 100000, raised: 12155, number: 419 },
  },
  {
    id: 'military',
    title: 'Military equipment',
    description:
      'Provide weapons and defence equipment to the ukrainian army for protecting the ukrainian people.',
    money: { goal: 250000, raised: 87981, number: 1255 },
  },
  {
    id: 'rebuild',
    title: 'Rebuild the Ukraine',
    description:
      'Donate to help us rebuild destroyed homes, schools and other buildings to let refugees return home.',
    money: { goal: 750000, raised: 137612, number: 1931 },
  },
]

const Fund = ({ info: { id, title, description, money } }) => {
  const progress = Math.round(money.raised / (money.goal / 100))
  return (
    <Flex
      direction="column"
      borderRadius={20}
      boxShadow="lg"
      my={5}
      mx={[0, 3, 5]}
      width={['100%', 'calc(50% - 24px)', 'calc(33% - 40px)']}
      className="fund-card"
      position="relative"
      _hover={{
        transition: 'transform .3s ease-out',
        transform: 'translateY(-10px)',
      }}
    >
      <Image src={`/fund_${id}.jpg`} borderTopRadius={10} />
      <Flex
        direction="column"
        p={3}
        flexGrow={1}
        transition="all .3s ease"
        sx={{ '.fund-card:hover &': { filter: 'blur(3px)' } }}
      >
        <Box fontSize={[16, 16, 18]} fontWeight={[800]} lineHeight={1} mt={3}>
          {title}
        </Box>
        <Box mt={2} mb={5} lineHeight={1.2} fontSize={[14, 14, 16]}>
          {description}
        </Box>
        <Progress size="sm" value={progress} borderRadius={10} mt="auto" />
        <Flex justify="space-between" mt={1} fontSize={14}>
          <Box>
            <Box>
              <b>€ {Math.round(money.raised / 1000)}k</b> raised
            </Box>
            <Box>
              <b>{money.number}</b> people
            </Box>
          </Box>
          <Box>
            Goal: <b>€ {Math.round(money.goal / 1000)}k</b>
          </Box>
        </Flex>
      </Flex>
      <Box
        position="absolute"
        bottom="20%"
        left="50%"
        transform="translateX(-50%)"
        display="none"
        sx={{ '.fund-card:hover &': { display: 'block' } }}
      >
        <DonateButton fund={id} shadow="dark-lg" />
      </Box>
    </Flex>
  )
}

const Funds = () => (
  <Container maxW="container.lg">
    <Flex my={[10, 16]} direction="column" align="center">
      <Box textStyle="blockLabel" mb={3}>
        Causes to care
      </Box>
      <Box textStyle="h2" mb={6} textAlign="center">
        Our targeted funds
      </Box>
      <Box maxW={500} textAlign="center" mb={10}>
        You can choose specifically what you would like to participate in and we
        will make sure your money are spent in the right direction.
      </Box>
      <Flex
        direction={['column', 'row']}
        align="stretch"
        flexWrap="wrap"
        ml={[0, -3, -5]}
        mr={[0, -3, -5]}
      >
        {funds.map(fund => (
          <Fund info={fund} key={fund.id} />
        ))}
      </Flex>
    </Flex>
  </Container>
)

export default Funds
